<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/OrderConfig/RefundList"
            >订单退款 /
          </router-link>
          <span class="crumbs_item crumbs_last">详情</span>
        </span>
      </div>
    </div>
    <div class="headTitle">
      <p>订单信息</p>
    </div>
    <div class="info content" v-if="orderInfo">
      <div class="option">姓名：{{orderInfo.userName}}</div>
      <div class="option">手机号：{{orderInfo.mobile ? $encryption('user:infomation:sensitivequery', 'phone', orderInfo.mobile) : '-'}}</div>
      <div class="option">支付时间：{{orderInfo.paymentTime}}</div>
      <div class="option">订单编号：{{orderInfo.orderCode}}</div>
      <div class="option">支付交易号：{{orderInfo.tradeId || '-'}}</div>
      <div class="option">支付方式：{{orderInfo.payMethod == 1 ? '微信' : orderInfo.payMethod == 2 ? '支付宝' : orderInfo.payMethod == 3 ? '对公转账' : ''}}</div>
      <div class="option">退款状态：{{orderInfo.refundStatus == 1? '待卖家处理' : orderInfo.refundStatus == 2? '部分退款' :orderInfo.refundStatus == 3? '已退款' :'--'}}</div>
      <div class="option">记录人：{{orderInfo.createName}}</div>
      <div class="option">实付款：{{orderInfo.payPrice}}</div>
      <div class="option">换课订单类型：{{orderInfo.changeProductFlag == 2?'换课新订单':orderInfo.changeProductFlag == 1?'换课老订单':'非换课订单'}}</div>
    </div>
    <div class="headTitle">
      <p>退款信息</p>
    </div>
    <a-table
      v-if="orderInfo"
      class="table-template content"
      :rowKey="(item,index) => index"
      :columns="columns"
      :data-source="orderInfo.refundVOS"
      :scroll="{ x: true }"
      :pagination="false"
    >
      <template slot="index" slot-scope="item, row, index">
        <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <template slot="productName" slot-scope="text">
        <p class="productName" :title="text">{{ text ? text : "--" }}</p>
      </template>

      <!-- 退款价格 -->
      <template slot="refundPrice" slot-scope="text, record">
        <a-input-number id="inputNumber" :max="record.payPrice" v-model="record.refundPrice"/>
      </template>

      <!-- 退款状态 -->
      <template slot="refundVoucher" slot-scope="text, ">
        <a :href="text.refundVoucher" v-if="text.refundVoucher" target="_blank">查看凭证</a>
        <template v-else>
          --
        </template>
      </template>
      
      <!-- 退款时间 -->
      <template slot="refundTime" slot-scope="text, ">
        <span>{{text || '--'}}</span>
      </template>

      <!-- 退款原因 -->
      <template slot="refundCause" slot-scope="text, record">
        <span>{{text}}</span>
        <span v-if="record.causeExplain"> - {{record.causeExplain}}</span>
      </template>

      <!-- 退款原因 -->
      <template slot="refundsOnly" slot-scope="text">
        <span>{{text == 1?'仅退款':text === 0?'退款退商品':'-'}}</span>
      </template>
    </a-table>
  
    <div class="btnoption">
      <a-button class="all_btn_border btnBorder" @click="$router.go(-1)">返回</a-button>
      <a-button class="btn" v-if="orderInfo && orderInfo.num > 0" v-hasPermi="['refund:info:updatestatus']" type="primary" @click="refundFeedBack()">处理反馈</a-button>
    </div>
    
    <!-- 处理反馈 -->
    <a-modal
      title="处理反馈"
      :width="449"
      :closable="false"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="visible = false"
      @ok="freeback"
      okText="完成退款"
    >
      <!-- <div class="option">
        <p>是否仅退款：</p>
        <a-select
          placeholder="数据处理状态"
          v-model="form.refundsOnly"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option value="true"> 仅退款 </a-select-option>
          <a-select-option value="false"> 退款退商品 </a-select-option>
        </a-select>
      </div> -->
      <div class="option">
        <p>退款时间：</p>
        <a-date-picker show-time valueFormat="YYYY-MM-DD HH:mm:ss" class="value" v-model="form.refundTime"/>
      </div>
      <div class="option">
        <p>退款凭证：</p>
        <a-upload
          class="value"
          list-type="picture-card"
          accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          :customRequest="customRequest"
        >
          <img v-if="form.refundVoucher" :src="form.refundVoucher" style="max-width:180px" alt="avatar" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
    scopedSlots: { customRender: "productName" },
  },
  {
    title: "原商品数量",
    align: "center",
    width: '110px',
    dataIndex: "productCount",
  },
  {
    title: "退款数量",
    align: "center",
    width: '100px',
    dataIndex: "refundCount",
  },
  {
    title: "实付价",
    align: "center",
    width: '80px',
    dataIndex: "orderPrice",
  },
  {
    title: "退款金额",
    align: "center",
    width: '100px',
    dataIndex: "refundPrice"
  },
  {
    title: "退款时间",
    align: "center",
    dataIndex: "refundTime",
    scopedSlots: { customRender: "refundTime" },
  },
  {
    title: "退款原因",
    align: "center",
    width: '220px',
    dataIndex: "refundCause",
    scopedSlots: { customRender: "refundCause" },
  },
  {
    title: "备注",
    align: "center",
    width: '200px',
    dataIndex: "remark",
  },
  {
    title: "是否仅退款",
    align: "center",
    width: '200px',
    dataIndex: "refundsOnly",
    scopedSlots: { customRender: "refundsOnly" },
  },
  {
    title: "退款凭证",
    align: "center",
    width: '120px',
    scopedSlots: { customRender: "refundVoucher" },
  }
];
export default {
  data() {
    return {
      columns, // 表头
      orderCode: "102023062902763", // 订单编号
      isChecked: false,
      selectedRowKeys: [], //选择的id
      tableData: [], // 商品数据
      orderInfo: null,
      loading: false,
      newLoading: false,
      handle_Status: undefined,
      disabledList: [],
      radioStyle: {
        // display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      orderId: '',

      // 处理反馈弹框
      loading: false,
      confirmLoading: false,
      visible: false,
      form: {
        // refundsOnly: undefined, // 是否仅退款
        refundVoucher: '',
        refundTime: '',
        orderId: ''
      }
    }
  },
  computed: {
    refundPrice() {
      let price = 0
      this.tableData.map(item=>{
        if(this.selectedRowKeys.indexOf(item.id) != -1) {
          if(item.refundPrice){
            price += item.refundPrice
          }
        }
      })
      return price;
    }
  },
  created() {
    this.orderId = this.$route.query.id
    this.getDetail()
  },
  methods: {
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 重置文件上传 - 单文件
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'user/transaction/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.form.refundVoucher = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 处理反馈弹框
    refundFeedBack() {
      this.visible = true;
      this.form.orderId = this.orderInfo.orderId;
      this.form.refundVoucher = '';
      this.form.refundTime = '';
      // this.form.refundsOnly = undefined;
    },

    // 查询详情
    getDetail() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/refund/getDetail",
        method: "get",
        params: {
          orderId: this.orderId
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.orderInfo = res.data;
        }else{
          this.$message.warning(res.message)
        }
      });
    },

    // 处理反馈
    freeback() {
      // if(!this.form.refundsOnly) {
      //   return this.$message.warning('请先选择是否仅退款！')
      // }else 
      if(!this.form.refundTime) {
        return this.$message.warning('请先选择退款时间！')
      }else if(!this.form.refundVoucher) {
        return this.$message.warning('请先上传退款凭证！')
      }
      this.confirmLoading = true;
      this.$ajax({
        url: "/hxclass-management/refund/feedback",
        method: "put",
        params: this.form
      }).then((res) => {
        this.confirmLoading = false;
        this.visible = false;
        if (res.code == 200 && res.success) {
          this.getDetail();
        }else if (res.code == 5000) {
          let _this = this;
          this.$confirm({
            title: res.message,
            okText: "知道了",
            type: 'warning',
            cancelButtonProps:{ style: { display: 'none' } },
            okButtonProps: {style: {margin: '0 auto'}},
            onOk() {
              _this.getDetail();
            }
          });
        }else{
          this.$message.error(res.message);
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
  .headTitle{
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
    p{
      font-size: 16px;
      color: #333333;
      line-height: 16px;
    }
    .func{
      font-size: 12px;
      color: @theme;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .info{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 20px;
    .option{
      width: 33%;
      font-size: 14px;
      margin-top: 10px;
    }
    .option:nth-child(n+4){
      margin-top: 20px;
    }
  }
  .btnoption{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
    .btn{
      margin-left: 20px;
    }
    p{
      width: 150px;
    }
    .ant-input{
      width: 200px;
    }
    .ant-input-affix-wrapper{
      flex: 1;
    }
  }
  .option{
    display: flex;
    margin-bottom: 20px;
    p{
      width: 100px;
      text-align: right;
    }
    .value{
      flex: 1;
    }
    /deep/.ant-select{
      flex: 1;
    }
  }
  .option:last-child{
    margin-bottom: 0px;
  }
</style>